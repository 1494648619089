import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { useCookies } from 'react-cookie';
import firebase from '../../../FirebaseConfig';
// import FirebaseAuthService from '../../../FirebaseAuthService';
// import useFirebase from '../../../firebase/useFirebase';

const AuthorizationPage = () => {
  const [readyToRender, setReadyToRender] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['user']);

  // const auth = firebase.auth();
  // console.log(`firebase.auth.currentUser`, auth.currentUser);

  const signInSuccessWithAuthResult = (result) => {
    console.log(`signInSuccessWithAuthResult`, result);
  };

  const singOut = () => {
    firebase.auth().signOut();
  };

  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/authorization',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult,
    },
  };

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        console.log(`onAuthStateChanged with user:`, user);
        setIsSignedIn(!!user);
        if (user) {
          console.log(`Setting User Cookie...`);
          setCookie('user', user, { path: '/' });
        } else {
          console.log(`Removing User Cookie (2)...`);
          removeCookie('user', { path: '/' });
        }
        console.log(`isSignedIn`, isSignedIn);
        setReadyToRender(true);
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, [setCookie, removeCookie]);

  // const firebase = useFirebase();

  // console.log(`firebase`, firebase);
  // console.log(`FirebaseAuthService`, FirebaseAuthService);

  // const onSignInWithGoogle = async () => {
  //   const result = await FirebaseAuthService.loginWithGoogle();
  //   console.log(`result`, result);
  // };

  // console.log(`firebase`, firebase);

  if (readyToRender) {
    if (!isSignedIn) {
      return (
        <>
          <Typography variant='h3' align='center' gutterBottom={true} p={5}>
            Authorization Page
          </Typography>
          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
          />
        </>
      );
    } else {
      return (
        <div>
          <Typography variant='h2' align='center' gutterBottom={true}>
            Magic Audio
          </Typography>

          <Box textAlign={`center`}>
            <p>
              Welcome {firebase.auth().currentUser.displayName}! You are now
              signed-in!
            </p>

            <Button onClick={singOut}>Sign-out</Button>
          </Box>
        </div>
      );
    }
  } else {
    return <></>;
  }
};

export default AuthorizationPage;
